.ant-modal-footer {
  display: none !important;
}
.ant-modal {
  width: 450px !important;
}
.ant-progress-text {
  display: none !important;
}
/* thead[class*="ant-table-thead"] th{
    background-color: #F2F2F2 !important;
  } */

.ant-modal-content {
  border-radius: 5px;
}
.alert_modal .ant-modal-content {
  /* box-shadow: none !important; */
  /* box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0 rgb(0 0 0 / 0%), 1px 6px 3px 0px rgb(0 0 0 / 0%) !important; */
  box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 25%), 0px 1px 0px 0 rgb(0 0 0 / 12%), 1px 6px 3px 0px rgb(0 0 0 / 3%) !important;
}
.alert_modal .ant-modal-body {
    padding: 9px 24px 20px 24px !important;
    
}
.alert_modal .ant-modal-header {
    padding: 19px 24px 8px 24px !important;
    
}
.saveBtn {
  padding: 10.4px 18.1px 10.7px 18.1px !important;
  background-color: #00ade2 !important;
  color: white !important;
  font-size: 14px !important;
  font-weight: bold !important;
}
.saveBtn:hover {
  background-color: #00ade2 !important;
  transition: 0.5s !important;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table,
.ant-table-tbody
  > tr
  > td
  > .ant-table-expanded-row-fixed
  > .ant-table-wrapper:only-child
  .ant-table {
  margin: -16px 0px -16px 0px !important;
}
.ant-modal-mask {
  position: relative;
}
