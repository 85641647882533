thead[class*="ant-table-thead"] th {
  background-color: white !important;
}
/* .ant-table-body  {
    overflow: auto;
} */

.ant-table-body::-webkit-scrollbar {
  width: 5px !important;
}

.ant-table-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.ant-table-body::-webkit-scrollbar-thumb {
  background: #888;
}

.ant-table-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-collapse-header-text{
  width: 100%;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 12px 0px;
}
.CheckOutlined {
  color: cornflowerblue;
  margin-top: 18px;
  font-size: large;
  font-Weight: bolder;
}
.chek_para{
    margin-top: 11px;
    font-weight: bolder;
    font-size: 16px;
    color: #685e5e;
}
.ant-card-body {
  padding: 26px!important;
}
/* width */
::-webkit-scrollbar {
  width: 2px !important;
  height: 2px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(148, 147, 147);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.discretionary-image img{
  width: 123px;
  margin-bottom: 10px;
}
.export-btn:hover{
  background-color: rgba(179, 179, 179, 0.28)!important;
  color: #2dade1!important;
}